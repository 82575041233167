import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useForm, windowDimension } from "@tools";
import { colors, jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { PhoneSelectionJack } from "../../../components/inputs/selection";
import {
  TextFieldJack,
  TextFieldJackPassword,
  TextFieldJackState,
} from "../../../components/inputs/textfield";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { apiBusiness, decryptToken, useMutation } from "../../../tools/api";
import { Layout, PasswordChecker } from "../components";
import { LoginInline } from "../forgotPassword/inline";
import styles from "../layout.module.css";
import { ErrorWatcher, ValidationChecker } from "./checker";
import { SidebarRegister } from "./sidebar";
import { ServiceAgreementCheckbox } from "./agreement";
import { ProductNeeds } from "./selection";
import { SimpleTextAreaJack } from "components/inputs/simpleTextArea";
import { phoneCode } from "assets/phoneCode";
import { eventsTracker } from "universalFunctions/events";
import { HIT_REGISTER_EVENT } from "pageComponents/kybJackComponents/parent";

const FirstStep = ({
  setCurrentScreen,
  setCurrentStep,
  payload,
  setPayload,
  isCreatePassword,
  onSubmit,
  setEmailSent,
  banner = false,
  loading,
}) => {
  // const {
  //   email: emailDefault,
  //   mobile: mobileDefault,
  //   mobile_country_iso: mobileIsoDefault,
  //   name: nameDefault,
  //   password: passwordDefault,
  //   password_confirmation: passwordConfirmationDefault,
  // } = payload?.user || {};
  const { push, query } = useRouter();

  const { isTabOrPhone: isResponsive, width } = windowDimension();
  const {
    password: passwordQ,
    password_confirmation: password_confirmationQ,
    referral_code: referral_code,
    utm_source = "",
    utm_medium = "",
    utm_campaign = "",
    page_source = "",
    event_name = "",
    average_monthly_revenue = "",
    utm_notes = "",
  } = query;

  const currentBanner = query?.banner;

  const isNewUser = !!currentBanner;

  const marketingData = {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_notes,
    page_source,
    banner: currentBanner || null,
    event_name,
    average_monthly_turnover: average_monthly_revenue,
  };

  const {
    email: email_query = "",
    name: name_query = "",
    business_name: business_name_query = "",
    mobile: mobile_query = "",
    business_id: business_id_query = null,
  } = query ?? {};

  const defaultValues = query?.password
    ? {
        ...query,
        password: decryptToken(passwordQ),
        password_confirmation: decryptToken(password_confirmationQ),
      }
    : query?.mobile && query.name && query.email && query.business_name
    ? {
        name: name_query,
        business_name: business_name_query,
        email: email_query,
        mobile: mobile_query,
        // mobile_country_iso: "",
        password: "",
        password_confirmation: "",
        referral_code: referral_code || "",
        sa: false,
        introduced_to_jack: "",
      }
    : {
        name: "",
        business_name: "",
        email: "",
        mobile: "",
        // mobile_country_iso: "",
        password: "",
        password_confirmation: "",
        referral_code: referral_code || "",
        sa: false,
        introduced_to_jack: "",
      };

  const { email } = query;

  const useFormObj = useForm({
    defaultValues,
  });

  const { errorSnackBar } = ToasterHook();

  const [error, setError] = useState({});
  const { watch, handleSubmit, setValue } = useFormObj;
  const passwordWatch = watch("password");

  const loginHandler = () => {
    push("/login");
  };

  const trackerValueDecider = () => {
    const isGeneral = currentBanner === "general";
    const isTransfezApp = currentBanner === "transfez_app";

    if (!isNewUser)
      return {
        page_title: "registration_page_no_banner",
        page_source: "",
      };

    if (isGeneral)
      return {
        page_title: "registration_page_general_banner",
        page_source,
      };

    if (isTransfezApp)
      return {
        page_title: "registration_page_transfez_app_banner",
        page_source,
      };

    // add below for new banner content
  };

  useEffect(() => {
    if (email) return setValue("email", email);
    // if (name_query) return setValue("name", name_query);
    // if (business_name_query)
    //   return setValue("business_name", business_name_query);
    // if (mobile_query) return setValue("business_name", mobile_query);
  }, [email]);

  const params = isNewUser
    ? {}
    : {
        demo_business_name: business_name_query,
        demo_email: email_query,
        demo_business_id: business_id_query,
      };

  useEffect(() => {
    eventsTracker("page_view", {
      ...trackerValueDecider(),
      ...params,
    });
  }, []);

  const isTransfezBanner = currentBanner === "transfez_app";

  // const registerTrackerDecider = isTransfezBanner
  //   ? { registration_page_transfez_app_banner: currentBanner }
  //   : { registration_page_general_banner: currentBanner };

  const { mutation: mutationRegister, loading: loadingRegister } = useMutation({
    type: "long",
    url: "/business_partners",
    method: "post",
    afterSuccess: (res, payload) => {
      // setCurrentScreen("checkEmailRegister");
      // setEmailSent(payload.user.email); // payload submit
      // const { password, password_confirmation, ...userWoPass } = payload.user;
      // payload.user = userWoPass;
      const { data } = res?.data ?? {};
      const { id: prod_business_id } = data ?? {};
      const { email: prod_email } = payload?.user ?? {};
      const bannerQuery = isNewUser ? `&banner=${currentBanner}` : "";
      eventsTracker(HIT_REGISTER_EVENT, {
        payload,
        page_title: isTransfezBanner
          ? "registration_page_transfez_app_banner"
          : "registration_page_general_banner",
        demo_business_id: +business_id_query,
        demo_business_name: name_query,
        demo_email: email_query, // kalo emailnya ganti gimana ttp demo email?
        prod_business_id,
        prod_email,
      });
      push(`/register/success?email=${payload.user.email}${bannerQuery}`);
    },
  });

  const defaultArr = [
    "name",
    "country_code",
    "mobile",
    "password",
    "password_confirmation",
  ];

  const defaultWatchers = isCreatePassword
    ? [...defaultArr, "email"]
    : [...defaultArr, "business_name", "sa", "products", "email"].filter(
        (item) => item
      );

  const formWatch = watch(defaultWatchers);

  ErrorWatcher({ formWatch, error });

  const errorFormatter = (error) => {
    const errorMessage = error?.response?.data?.error?.message || "";
    const isEmailNotExist =
      errorMessage.includes("40101") ||
      errorMessage.includes("Email is not registered in the database");
    const isEmailExist = errorMessage === "Invalid password";

    return { isEmailNotExist, isEmailExist };
  };

  const registerDisabled = formWatch.sa === false;

  const submit = async (data) => {
    const isError = ValidationChecker({ formWatch, setError });
    if (isError) return;

    if (onSubmit) return onSubmit(data);

    const {
      name,
      business_name,
      email,
      mobile,
      password,
      password_confirmation,
      referral_code,
      country_code,
      products,
      introduced_to_jack,
    } = data || {};

    const registerPayload = {
      user: {
        name,
        email,
        mobile,
        password,
        password_confirmation,
        country_code:
          phoneCode.find(({ code }) => code == country_code)?.value || "+62",
      },
      business: {
        name: business_name,
        referral_code,
        product_needs: { products },
        marketing_need: {
          ...marketingData,
        },
        additional_info: {
          introduced_to_jack,
        },
      },
    };

    mutationRegister(registerPayload);
    // try {
    //   if (referral_code) {
    //     data.referral_code = referral_code;
    //   }
    //   const { email } = data || {};
    //   // await apiBusiness.post("/authenticate", { email, is_register: true });
    // } catch (err) {
    //   const { isEmailNotExist } = errorFormatter(err);

    //   if (isEmailNotExist) {
    //     const mobile_country_iso = data.country_code.value;
    //     data = { ...data, mobile_country_iso, is_pic_business: true };
    //     delete data.country_code;
    //     setPayload({ user: data });
    //     setCurrentStep && setCurrentStep(2);
    //     return;
    //   }
    //   errorSnackBar({ msg: "Email already exist" });
    // }
  };

  const buttonStyle = {
    marginLeft: "auto",
    width: "40px",
    padding: 0,
  };

  const textWithRedAsterix = (text) => (
    <>
      {text}
      <span style={{ color: "red" }}>*</span>
    </>
  );

  const password = (
    <>
      <TextFieldJackPassword
        useFormObj={useFormObj}
        label={textWithRedAsterix("Password")}
        placeholder="Enter your password"
        name="password"
        error={error["password"]}
      />
      <PasswordChecker
        className={
          passwordWatch
            ? styles["password-checker-visible"]
            : styles["password-checker-hidden"]
        }
        isError={error?.pass_template}
        password={passwordWatch}
      />
      <TextFieldJackPassword
        useFormObj={useFormObj}
        label={textWithRedAsterix("Confirm Password")}
        placeholder="Repeat your password"
        name="password_confirmation"
        error={error["password_confirmation"]}
      />
    </>
  );

  const mobile = (
    <div
      style={{
        display: "flex",
        gap: "16px",
      }}
    >
      <PhoneSelectionJack name="country_code" useFormObj={useFormObj} />
      <TextFieldJack
        name="mobile"
        label={textWithRedAsterix("Phone Number")}
        placeholder="E.g., 8123456789"
        type="number"
        useFormObj={useFormObj}
        style={{ width: "100%" }}
        error={error["mobile"]}
      />
    </div>
  );

  const array = [
    // { label: "Corporate Cards", value: "cards" }, // temporarily take out cards from the selection
    { label: "International Transfer", value: "international_transfer" },
    { label: "Bill Payment", value: "invoice_payment" },
    { label: "Local Transfer", value: "local_transfer" },
    { label: "Payroll", value: "payroll" },
    { label: "Reimbursement", value: "reimbursement" },
    { label: "Virtual Account", value: "va_collection" },
  ];

  const formWidthDecider = () => {
    if (isResponsive) return "100vw";
    if (isNewUser) return "calc(100vw - 432px)";
    return "100vw";
  };

  if (isCreatePassword)
    return (
      <Layout
        title="Take the first step by creating your Jack account"
        setCurrentScreen={setCurrentScreen}
        woJumper
      >
        <div className={styles["form"]}>
          <TextFieldJack
            name="name"
            label="Full Legal Name"
            placeholder="Enter your full legal name"
            useFormObj={useFormObj}
            error={error["name"]}
          />
          <TextFieldJackState
            value={email}
            label="Email"
            disabled
            helperText="This email will be used to complete your company registration process."
          />
          {password}
          {mobile}
          <ButtonJack
            onClick={handleSubmit(submit)}
            isLoading={loading || loadingRegister}
            style={buttonStyle}
          >
            Register
          </ButtonJack>
        </div>
      </Layout>
    );

  return (
    <div
      // className={registerStyles["wrapper"]}
      style={{
        display: "flex",
        flexDirection: isResponsive ? "column" : "row",
      }}
    >
      {/* <div className={"d-flex"}> */}
      {isNewUser && (
        <div style={isResponsive ? { minWidth: 100 } : { minWidth: 432 }}>
          <SidebarRegister
            currentBanner={currentBanner}
            withNavMenu={isResponsive}
            wrapperStyle={
              isResponsive
                ? { width: "fit-content" }
                : { position: "fixed", top: "0px", paddingTop: 0 }
            }
            iconStyle={isResponsive ? {} : { height: 80 }}
            // wrapperClass={registerStyles["sidebar-content"]}
          />
        </div>
      )}

      <div
        className={`d-flex align-items-center`}
        style={{
          width: formWidthDecider(),
          flexDirection: "column",
        }}
      >
        {!isResponsive && (
          <div
            className="w-100 align-items-end justify-content-end d-flex"
            style={{ padding: 20, paddingRight: 32 }}
          >
            <ButtonJack
              type="outline"
              rightIcon={<JackIcons name="log-out-outline" fill="black" />}
              onClick={() => push("/login")}
            >
              Log in
            </ButtonJack>
          </div>
        )}
        <div style={isResponsive ? { maxWidth: width } : { width: 462 }}>
          {/* <GothamMedium
            className="font24"
            style={{ textAlign: "center", marginBottom: 32, marginTop: 24 }}
          >
            Take the first step by creating <br />
            your Jack account
          </GothamMedium> */}
          {/* {contentDecider()} */}
          <Layout
            title="Take the first step by creating your Jack account"
            setCurrentScreen={setCurrentScreen}
            woJumper={isCreatePassword}
            wrapperStyle={
              isResponsive
                ? { maxWidth: width, paddingTop: 40 }
                : { paddingTop: 32 }
            }
            woIcon
          >
            <form
              className={styles["form"]}
              onSubmit={handleSubmit(submit)}
              style={isResponsive ? { padding: `0px 12px` } : {}}
            >
              <TextFieldJack
                name="name"
                label={textWithRedAsterix("Full Legal Name")}
                placeholder="Enter your full legal name"
                useFormObj={useFormObj}
                error={error["name"]}
              />
              <TextFieldJack
                name="business_name"
                label={textWithRedAsterix("Business Legal Name")}
                placeholder="Enter your business name"
                useFormObj={useFormObj}
                error={error["business_name"]}
              />
              <TextFieldJack
                name="email"
                label={textWithRedAsterix("Email")}
                placeholder="Enter your email"
                useFormObj={useFormObj}
                error={error["email"]}
              />
              {/* <TextFieldJackState
                name="email"
                label="Email"
                useFormObj={useFormObj}
                error={error["email"]}
                value={formWatch.email}
                // helperText="This email will be used to complete your company registration process."
                // placeholder="Enter your email"
                required
              /> */}
              {password}
              {mobile}
              <ProductNeeds
                useFormObj={useFormObj}
                name="products"
                label={textWithRedAsterix("Product Needs")}
                options={array}
                error={error["products"]}
              />
              <SimpleTextAreaJack
                useFormObj={useFormObj}
                name="introduced_to_jack"
                maxLength={120}
                placeholder="Enter the company name that referred you to us"
                label={
                  <>
                    Who introduced you to Jack?{" "}
                    <span
                      className="font10"
                      style={{ color: jackColors.grey90 }}
                    >
                      (Optional)
                    </span>
                  </>
                }
              />
              <ServiceAgreementCheckbox name="sa" useFormObj={useFormObj} />
              <ButtonJack
                // disabled={isButtonDisabled}
                // rightIcon={rightIcon}
                disabled={registerDisabled}
                isLoading={loadingRegister}
                style={buttonStyle}
              >
                Register
              </ButtonJack>
            </form>
            {isResponsive && (
              <GothamRegular
                style={{ marginTop: 26, color: jackColors.neutral900 }}
              >
                Already have an account?
                <span
                  // className="link"
                  style={{
                    marginLeft: 5,
                    fontWeight: "bolder",
                    textDecoration: "none",
                    fontFamily: "GothamBook",
                    fontSize: 14,
                    borderBottom: `1px solid ${jackColors.neutral900} `,
                  }}
                  onClick={() => push("/login")}
                >
                  Log In
                </span>
              </GothamRegular>
            )}
          </Layout>
        </div>
      </div>
      {/* <img src="/images/sidebar.png" style={{ height: "100vh" }} /> */}
    </div>
  );
};

export default FirstStep;
